import { Component, Input ,OnInit } from '@angular/core';
import { IBreadcrumb } from '../sub-header/sub-header.modal';
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET } from "@angular/router";
import "rxjs/add/operator/filter";
import { ISubheaderLeftSection} from '../../../shared-main/components/sub-header/sub-header.modal';
import { SubheaderHandlerService } from './../../../core/nav/subheader-handler.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'nalco-breadcrumb',
    templateUrl: './breadcrumb.component.html'
})


export class BreadcrumbComponent implements OnInit {
  leftSection: ISubheaderLeftSection;
  ssd: boolean = false;
    @Input() data: IBreadcrumb;
  // public data: IBreadcrumb[];
  private subscriptions: Subscription[] = [];
  
    constructor(private router: Router,private subHeaderService: SubheaderHandlerService) {

    }
 
  
  ngOnInit() {
    this.subscriptions.push(this.subHeaderService.subheaderLeftSection$.subscribe(async (update) => {
      this.ssdBreadcrumb();
    }));
  }
    
  ssdBreadcrumb() {
    if (this.router.url === '/service-summary') {
      this.ssd = true;
    }


    // const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

    // //subscribe to the NavigationEnd event
    // this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {

    //   //set data
    //   let root: ActivatedRoute = this.activatedRoute.root;
    //   this.data = this.getBreadcrumbs(root);
    // });
  }
    
  //   private getBreadcrumbs(route: ActivatedRoute, link: string="", data: IBreadcrumb[]=[]): IBreadcrumb[] {
  //     const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";
  
  //     //get the child routes
  //     let children: ActivatedRoute[] = route.children;
  
  //     //return if there are no more children
  //     if (children.length === 0) {
  //       return data;
  //     }
  
  //     //iterate over each children
  //     for (let child of children) {
  //       //verify primary route
  //       if (child.outlet !== PRIMARY_OUTLET) {
  //         continue;
  //       }
  
  //       //verify the custom data property "breadcrumb" is specified on the route
  //       if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
  //         return this.getBreadcrumbs(child, link, data);
  //       }
  
  //       //get the route's URL segment
  //       let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");
  
  //       //append route URL to URL
  //       link += `/${routeURL}`;
  
  //       //add breadcrumb
  //       let breadcrumb: IBreadcrumb = {
  //         text: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
  //         link: link
  //       };
  //       data.push(breadcrumb);
        
  //       //recursive
  //       return this.getBreadcrumbs(child, link, data);
  //     }
      
  //     return data;
  //   }
  
  }
  