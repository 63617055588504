import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from './shared-main/models/_models';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppEnvConfig } from './app.env.config';
import { LocalCacheService } from './core/local-cache/local-cache.service';
import { UserService } from './core/user/user.service';
import { Subscription } from 'rxjs/Subscription';
import { AppDynamicsService } from './core/analytics/app-dynamics.service';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { LooperService } from './shared-main/services/looper.service';
import { MsalService } from './core/msal/msal.service';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';


@Component({
  selector: 'nalco-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  loading: Boolean = false;
  location: String;
  hideLayout: boolean = false;
  user: User;
  isMobile: Boolean = false;
  isUserInfoAvailable: boolean = false;
  userInfoCache: any;
  allannouncement = [];
  chachedAllAnnouncemnets:any;
  private subscriptions: Subscription[] = [];
  private alive: boolean;
  height: number;
  screenWidth: number;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private config: AppEnvConfig,
    private localCacheService: LocalCacheService,
    private userService: UserService,
    private msalService: MsalService,
    private appDynamicsService: AppDynamicsService,
    private looper: LooperService
  ) {
    console.log('App module called');
    this.userInfoCache = this.localCacheService.fetchData('msalUserInfo');
    this.userInfoCache = JSON.parse(this.userInfoCache);
    if (!this.userInfoCache) {
      let redirectURL = (window.location.hash).slice(1);
      if(redirectURL.indexOf('code')<0){
        console.log(redirectURL);
        this.localCacheService.storeData('returnUrl', redirectURL);
      }
      this.loading = true;
      this.msalService.login();
    } else {
      this.msalService.userInfo = this.userInfoCache;
    }
    this.appDynamicsService.adrumAddScript()
    this.alive = true;
  }


  ngOnInit(): void {
    this.msalService.userInfoDetails.subscribe(userData => {
      if (userData || this.userInfoCache) {
        this.isUserInfoAvailable = true;
        this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
        this.user = new User(this.msalService.userInfo);
        if(this.user) {
          this.loading = false;
        }
        console.log('App Component ---- this.user');
        console.log(this.user);
        console.log('Check if Username is there? '+this.msalService.userInfo.EmailAddress);
        this.translate.addLangs(this.config.getEnv('languages'));
        this.translate.setDefaultLang(this.userService.getUserCulture());
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            this.hideLayout = this.activeRoute.firstChild.snapshot.data.hideLayout;
          }
        });

        if (this.user && this.user.authenticated) {
          this.localCacheService.storeData('username', this.user.userPrincipalName);
          this.looper.checkVersion(true);
          const loopMinutes: number = 10 * 60 * 1000; // 10 minutes, todo: make it 30minutes
          // IntervalObservable.create(loopMinutes)
          //   .takeWhile(() => this.alive) // only fires when component is alive
          //   .subscribe(() => {
          //     console.log('Looper is connecting...');
          //     this.looper.get()
          //       {
          //         console.log('Token refreshed, Looper fetched new permissions:');
          //       };
          //     this.looper.checkVersion();
          //   });

          // changed as we have issues with intervalobservable
            interval(loopMinutes).pipe(
            takeWhile(() => this.alive))
           .subscribe(() => {
            console.log('Looper is connecting...');
            this.looper.get()
              {
                console.log('Token refreshed, Looper fetched new permissions:');
              };
            this.looper.checkVersion();
           });
           this.showAnnouncements(this.msalService.userInfo.UserId);     
        }

        // on route change scroll back to the top
        this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd)) {
            return;
          }
          this.checkVersion();
          window.scrollTo(0, 0);
        });
      } else {
        this.isUserInfoAvailable = false;
      }
    },
    error => {
      // receiving the error to show appropriate message to user if there is no access to application
      if (error.status === 417) {
        this.isUserInfoAvailable = false;
        this.translate.addLangs(this.config.getEnv('languages'));
        this.translate.setDefaultLang(this.userService.getUserCulture());
        this.loading = false;
      }
    }
    );
  }

  private checkVersion() {
    this.looper.checkVersion();
  }
  showAnnouncements(userId:any)
  {
    this.chachedAllAnnouncemnets = this.localCacheService.fetchData('AllAnnouncemnet');
    this.allannouncement=JSON.parse(this.chachedAllAnnouncemnets);
    if(this.allannouncement && this.allannouncement.length>0){
      this.allannouncement.forEach(item => {
       if(this.localCacheService.fetchData('AnnouncemnetStatus'+item.AnnouncementId)==="Announcemnet"+item.AnnouncementId){
         item.Isdisable=false;
      }});
      this.setPopUpPosition();
    }
    else{
    this.showPopup(userId);
    }
  }
  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  showPopup(userId:any) {
      this.allannouncement=[];
      if(userId){
      this.userService.getLatestAnnouncements(userId).subscribe(response => {
          if (response && response.length > 0) {
            response.forEach(element => {
              const dateToday = new Date();
              let endDate = new Date(element.EndDate);
              if (dateToday <= endDate) {
           this.allannouncement.push({
            AnnouncementId:element.Id,
            Message:element.Message,
            EndDate:element.EndDate,
            Isdisable:true
         });
        }
          this.setPopUpPosition();
          this.localCacheService.storeData('AllAnnouncemnet', JSON.stringify(this.allannouncement));
     
            });
          }
        });  
      }
  }
  onCloseClick(index,announcementId){
      if(announcementId){
        this.localCacheService.storeData('AnnouncemnetStatus'+announcementId, "Announcemnet"+announcementId);
        this.allannouncement.splice(index,1);
        this.setPopUpPosition();
      }
  }
  onGotItClick(index,announcementId)
  {
        let userId = this.msalService.userInfo.UserId;
        if(userId && announcementId){
        this.userService.saveAnnouncementStatusForUser(userId,announcementId).subscribe(response => {
          if (response) {
            this.localCacheService.storeData('AnnouncemnetStatus'+announcementId, "Announcemnet"+announcementId);
            this.allannouncement.splice(index,1);
            this.setPopUpPosition();
          }        
        });
      }
  }
  setPopUpPosition() {
    if(this.allannouncement.length>0){
    let i=0,x:any,y:any,z:any;
    x=130;y=this.getWindowWidth();z=x+y; 
    this.allannouncement.forEach(item => {
      if(item.Isdisable!==false){
     if(i===0){
       item.top=0;
     }
     else if(i===1){
       item.top=z;
     }
     else{
       z=z+y+130;
       item.top=z;
     }
     i++;
    }
    });
  }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setPopUpPosition();
  }
  getWindowWidth() {
    this.screenWidth = window.innerWidth;
     if (this.screenWidth < 540) {
       return this.height = this.isMobile?260:200;
     } else if (this.screenWidth < 767 && this.screenWidth > 541) {
        return this.height = 180;
     } else if (this.screenWidth < 1025 && this.screenWidth > 767) {
       return this.height = 100;
     } else {
       return this.height = 80;
     }
  }

}
