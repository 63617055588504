// version 1.3 with much improved code
// handled selective itemized obserable
// handled application memory loss

import { Injectable, Optional } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';

// model for storage configuration
export class LocalCacheMode {
    mode = '';
    namespace = '';
}

export class LocalKey {
    key: string;
    subject: any;
}

@Injectable()
export class LocalCacheService {

    public _store: Storage;

    public keys: Array<LocalKey> = [];

    private namespace = '';

    constructor( @Optional() config: LocalCacheMode) {
        if (config === undefined || config === null) {
            // if config is option then provide defaults here
            config.mode = 'session';
        }
        if (config.mode === 'session') {
            this._store = sessionStorage;
        } else {
            this._store = localStorage;
        }
        this.namespace = config.namespace;
    }

    watchStore(key: string): Observable<any> {
        key = this.getFormalKey(key);

        const localKey = this.getLocalKey(key);
        if (localKey) {
            return localKey.subject;
        } else {
            return Observable.of(null);
        }
    }

    public fetchData(key: string, source?: 'session'|'local'): any {
        const store = this.setSource(source);
        key = this.getFormalKey(key);

        return store.getItem(key);
    }

    public storeData(key: string, value: any, source?: 'session'|'local'): void {
        const store = this.setSource(source);
        key = this.getFormalKey(key);
        // for memory
        if (!this.getLocalKey(key)) {
            // when key doesn't exists in browser or memory
            const subject = new Subject<any>();
            this.keys.push({ key: key, subject: subject });
        }

        // for browser
        if (!this.hasKey(key) || store.getItem(key) !== value) {
            // if browser doesn't have store it
            // or if browser has it but different
            store.setItem(key, value);
        }

        // now inform the subscribers
        this.getLocalKey(key).subject.next({ key: key, value: value });
    }

  public hasKey(key: string, source?: 'session'|'local'): boolean {
        const store = this.setSource(source);
        key = this.getFormalKey(key);
        return store[key] !== undefined;
    }

    public clearAll(source?: 'session'|'local'): void {
        const store = this.setSource(source);
        store.clear();
        this.keys = [];
    }

    public clearData(key: string, source?: 'session'|'local'): void {
        const store = this.setSource(source);
        key = this.getFormalKey(key);
        store.removeItem(key);
        this.keys = this.keys.filter(e => e.key !== key);
    }

  public storeJson(key, data, source?: 'session'|'local') {
        this.storeData(key, JSON.stringify({
            value: data,
            lastUpdatedOn: moment().toDate()
          }), source);
    }

  public fetchJson(key, days, source?: 'session'|'local') {
    const data = JSON.parse(this.fetchData(key, source));
        if (data && moment().diff(data.lastUpdatedOn, 'days') <= days) {
            return data.value;
        }
        return null;
    }

    private getFormalKey(key) {
        return this.namespace + key;
    }

  private setSource(source?: 'session'|'local') {
        let store = this._store;
        if (source !== undefined) {
            store = (source === 'session') ? sessionStorage : localStorage;
        }
        return store;
    }

    private getLocalKey(key: string): LocalKey {
        let item: LocalKey;
        this.keys.forEach(k => {
            if (key === k.key) {
                item = k;
            }
        });
        return item;
    }

}
