import { Injectable } from '@angular/core';
import { AppEnvConfig } from '../../app.env.config';
import { LocalCacheService } from '../local-cache/local-cache.service';
import { ErrorHandling } from '../../shared/components/error-handling/error-handling';
import { User } from '../../shared/models/User';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../logger/global-logger.service';

declare var msal: any;

@Injectable()
export class MsalService {
  public configEnv: any = {};
  public msalInstance: any;
  public endpoints = {};
  public user: User;
  public selectedEndpoint: string;
  public loginRequest: object;
  public tokenRequest: object;
  public apiRequestscope: object;
  public userInfo: any;
  public username: any;
  public IsB2CEnable: string;
  public homeAccountId: any;
  public returnUrl: any;
  public userInfoDetails = new BehaviorSubject<any>(null);
  public errorHandling = new ErrorHandling(
    this.modalService,
    this,
    this.translate
  );
  $userInfoDetails = this.userInfoDetails.asObservable();
  constructor(
    private config: AppEnvConfig,
    private cache: LocalCacheService,
    private http: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private logger: LoggerService,
    private activeRoute: ActivatedRoute
  ) {
    this.IsB2CEnable = config.getEnv('IsB2CEnable').toString();
    this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');

    console.log('B2C is ' + this.IsB2CEnable);
    this.endpoints = config.getEnv('msalConfig').endpoints;
    this.selectedEndpoint =
      this.endpoints[this.configEnv.apiServerUrl.slice(0, -1)] +
      '/user_impersonation';

    if (this.IsB2CEnable == 'true') {
      this.msalInstance = new msal.PublicClientApplication(this.msalB2CConfig);
      this.loginRequest = {
        scopes: ["openid", this.selectedEndpoint],
      };

      this.tokenRequest = {
        scopes: ["openid", this.selectedEndpoint],
      };

      this.apiRequestscope = {
        scopes: [this.selectedEndpoint],
      };
    } else {
      this.msalInstance = new msal.PublicClientApplication(this.msalConfig);
      this.loginRequest = {
        scopes: ['user.read'],
      };

      this.tokenRequest = {
        scopes: ["User.Read"],
        forceRefresh: true, // Set this to 'true' to skip a cached token and go to the server to get a new token
      };

      this.apiRequestscope = {
        scopes: [this.selectedEndpoint],
        forceRefresh: true, // Set this to 'true' to skip a cached token and go to the server to get a new token
      };
    }
    this.returnUrl = this.activeRoute.snapshot.queryParams['returnUrl'] || '/';
  }

  public b2cPolicies = {
    names: {
      signUpSignIn: this.config.getEnv('signUpSignInPolicy'),
    },
    authorities: {
      signUpSignIn: {
        authority: this.config.getEnv('authority'),
      },
    },
    authorityDomain: this.config.getEnv('authorityDomain'),
  };

  public msalConfig = {
    auth: {
      clientId: this.config.getEnv('msalConfig').clientId,
      authority:
        this.config.getEnv('authorityUrl') +
        this.config.getEnv('msalConfig').tenant +
        '/',
      validateAuthority: true,
      navigateToLoginRequetUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    },
    system: {
      tokenRenewalOffsetSeconds: 300,
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.log(message);
              return;
            case msal.LogLevel.Verbose:
              console.log(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  };

  public msalB2CConfig = {
    auth: {
      clientId: this.config.getEnv('msalConfig').clientId,
      authority: this.b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [this.b2cPolicies.authorityDomain],
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    },
    system: {
      tokenRenewalOffsetSeconds: 300,
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.log(message);
              return;
            case msal.LogLevel.Verbose:
              console.log(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  };

  login() {
    // this.msalInstance.loginRedirect(this.apiRequestscope);
    this.msalInstance
      .handleRedirectPromise()
      .then((resp) => {
        if (resp == null) {
          const cacheAccount = this.msalInstance.getAllAccounts();
          if (cacheAccount.length === 1) {
            this.homeAccountId = cacheAccount[0].homeAccountId;
            this.apiRequestscope['account'] = this.homeAccountId;
            this.cache.storeData('HomeAccountID', this.homeAccountId);
            const cacheUsername = this.cache.fetchData('username');
            const siletTokenApiRequestScope = {
              ...this.apiRequestscope,
              sid: cacheUsername,
              login_hint: cacheUsername,
            };
            this.msalInstance
              .acquireTokenSilent(siletTokenApiRequestScope)
              .then((tokenResponse) => {
                // Do something with the tokenResponse
                this.getProfileDetails(tokenResponse.accessToken);
                console.log(tokenResponse);
              })
              .catch((error) => {
                // call acquireTokenPopup in case of acquireTokenSilent failure
                // due to consent or interaction required
                if (
                  error.errorCode === 'consent_required' ||
                  error.errorCode === 'interaction_required' ||
                  error.errorCode === 'login_required' ||
                  error.errorCode === 'ClientAuthError'
                ) {
                  this.msalInstance
                    .acquireTokenPopup(this.apiRequestscope)
                    .then((tokenResponse) => {
                      this.getProfileDetails(tokenResponse.accessToken);
                      console.log(tokenResponse);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                if (error instanceof msal.InteractionRequiredAuthError) {
                  // fallback to interaction when silent call fails
                  this.msalInstance
                    .acquireTokenPopup(this.apiRequestscope)
                    .then((tokenResponse) => {
                      this.getProfileDetails(tokenResponse.accessToken);
                      console.log(tokenResponse);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              });
          } else {
            console.log(
              'response is null of handleRedirectPromise ---> navigating for re-login the user '
            );
            this.msalInstance.loginRedirect(this.apiRequestscope);
          }
        } else {
          console.log('Got the response from the call', resp);
          const currentAccounts = this.msalInstance.getAllAccounts();
          if (currentAccounts === null) {
            console.log('No account name received');
          } else if (currentAccounts.length === 1) {
            this.homeAccountId = currentAccounts[0].homeAccountId;
            this.cache.storeData('HomeAccountID', this.homeAccountId);
            //get details from Token API
            this.getProfileDetails(resp.accessToken);
            this.cache.storeData('MSALToken', resp.accessToken);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('inside the login ', this.msalInstance);
  }

  getProfileDetails(token: any) {
    this.cache.storeData('MSALToken', token);
    let urlPermissions = this.configEnv.apiServerUrl + 'Users/Token';
    return this.http.get(urlPermissions).subscribe(
      (next) => {
        this.userInfo = next;
        this.cache.storeData('msalUserInfo', JSON.stringify(this.userInfo));
        this.userInfoDetails.next(this.userInfo);
        this.user = new User(this.userInfo);
      },
      (error) => {
        if (error.status === 417) {
          //throwing error if the user does not have access to application
          this.userInfoDetails.error(error);
          this.router.navigate(['/access-denied']);
        } else if (error.status === 404) {
          this.router.navigate(['/**']);
        } else {
          this.errorHandling.showErrorPopup(error);
        }
        this.translate.get('COMMON.GENERIC_ERROR_MESSAGE').subscribe((res) => {
          this.logger.error({ message: res }, false, true);
        });
      }
    );
  }

  refreshToken() {
    //session token refreshed
    this.getTokenPopup(this.apiRequestscope);
    this.getProfileDetails(this.cache.fetchData('MSALToken'));
    console.log('API token refresh');
  }

  getTokenPopup(request) {
    console.log('token popup called', request);
    let HomeAccountID = this.cache.fetchData('HomeAccountID');
    const cacheUsername = this.cache.fetchData('username');
    /** See here for more info on account retrieval: * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md */
    request.account = this.msalInstance.getAccountByHomeId(HomeAccountID);
    const requestSilent = {
      ...request,
      sid: cacheUsername,
      login_hint: cacheUsername,
    };
    return this.msalInstance
      .acquireTokenSilent(requestSilent)
      .catch((error) => {
        console.warn(
          'silent token acquisition fails. acquiring token using redirect'
        );
        if (
          error.errorCode === 'consent_required' ||
          error.errorCode === 'interaction_required' ||
          error.errorCode === 'login_required' ||
          error.errorCode === 'ClientAuthError'
        ) {
          this.msalInstance
            .acquireTokenPopup(request)
            .then((tokenResponse) => {
              console.log(tokenResponse);
              //return tokenResponse;
              return this.msalInstance.acquireTokenRedirect(request);
            })
            .catch((error) => {
              console.error(error);
            });
        }
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return this.msalInstance
            .acquireTokenPopup(request)
            .then((tokenResponse) => {
              console.log(tokenResponse);
              //return tokenResponse;
              return this.msalInstance.acquireTokenRedirect(request);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.warn(error);
        }
      });
  }

  clear(MSALToken?) {
    // this.cache.clearAll('local');
    if (MSALToken) {
      this.cache.clearData('MSALToken');
      this.cache.clearData('msalUserInfo');
    } else {
      this.cache.clearAll();
    }
  }

  logOut() {
    this.clear();
    const logoutRequest = {
      account: this.msalInstance.getAccountByHomeId(this.homeAccountId),
    };
    this.msalInstance.logout(logoutRequest);
    console.log('logged out');
  }
}
