import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user/user.service';
import { UserSettings } from '../../../shared/models/User';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AppEnvConfig } from '../../../app.env.config';
import { MsalService } from '../../msal/msal.service';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private userService: UserService,
    private msalService: MsalService,
    private translation: TranslateService,
    private loadEnv: AppEnvConfig
  ) {
    console.log("Authentication guard called");
   }

   canLoad(route: Route): boolean {
    if (this.msalService.userInfo) {
      return true;
    } else {
      // console.log('can load guard...');
      const url = `/${route.path}`;
      this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const user: UserSettings = this.userService.getCurrentSettings();
    if (user && user.Permissions) {
         // console.log('route guard passed succesfully for ' + state.url);
      const userCulture = user.Culture.match(/en|es|pt|zh|de|nl|fr|ru|it/);
      const userLanguage = userCulture != null ? userCulture[0] : 'en';
      this.translation.use(userLanguage);
      return Observable.of(true);
    } else {
      // console.log('route guard access blocked for ' + state.url);
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return Observable.of(false);
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
