import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { User } from '../../models/_models';
import { headerHandlerService } from './../../../core/nav/header-handler.service';
import { LocalCacheService } from '../../../core/local-cache/local-cache.service';
import { MsalService } from '../../../core/msal/msal.service';

@Component({
  selector: 'nalco-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input() user: User; // Defines the user Authentication
  @Output() onFilter = new EventEmitter();
  profileDdToggle = false;
  Initials = '';
  collapsed = true;
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  constructor(private headerService: headerHandlerService, private msalService: MsalService, private cache: LocalCacheService) {
  }

  @HostListener('document:click', ['$event']) clickedOutside(event) {
    if  ((event.target.className.indexOf  !==  undefined
       &&  event.target.className.indexOf('LeftNavigation-link')  >  0)
      ||  (event.target.innerText  !==  undefined  &&  event.target.innerText  ===  'HOME') ||
      (event.target.className.indexOf  !==  undefined && event.target.className.indexOf('overlay')  >=  0)) {
      this.collapsed  =  true;
    }
  }

  ngOnInit() {
    //  this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
    let initial = '';
    const givenName = this.checkUserName(this.msalService.userInfo.userPrincipalName || this.msalService.userInfo.EmailAddress);
    if (givenName) {
      initial = givenName.substring(0, 1);
    }
    if (this.msalService.userInfo.surname || this.msalService.userInfo.LastName) {
      let surname = this.msalService.userInfo.surname || this.msalService.userInfo.LastName;
      if (surname) {
        initial += surname.substring(0, 1);
      } else {
        // fallback to get from given_name, if family name is null
        let firstName = this.msalService.userInfo.FirstName || this.msalService.userInfo.displayName;
        initial += firstName.split(' ')[1].substring(0, 1);
      }
    }
    this.Initials = initial.toUpperCase();
  }

  logout() {
    this.msalService.logOut();
  }

  clickBtn() {
    this.headerService.btnclickhead$(event);
  }

  showProfileSubMenu() {
    this.profileDdToggle = !this.profileDdToggle;
  }

  // Checking given_name is undefined
  checkUserName(name) {
    if (name !== undefined) {
      return name;
    } else {
      return this.msalService.userInfo.FirstName;
    }
  }
}
