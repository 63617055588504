import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { UserSettings } from '../../shared/models/User';
import { AppEnvConfig } from '../../app.env.config';
import { AccountSearch } from '../../shared/models/AccountSearch';
import { LocalCacheService } from '../../core/local-cache/local-cache.service'
import {Permissions} from '../../shared/models/Permissions';
import { HttpClient } from '@angular/common/http';
import {forkJoin} from 'rxjs';

@Injectable()
export class UserService {
    private configEnv: any = {};
    private defaultExpires: number = 86400; //24Hrs
    private userSettingsKey: string = 'UserSettings';
    private userRole: string = 'UserRole';
    constructor(
        private config: AppEnvConfig,
        private cache: LocalCacheService,
        private httpClient: HttpClient) {
        this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
        this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');

    }

    getUserSettings(): Observable<UserSettings> {
        let urlAcccounts = `${this.configEnv.apiServerUrl}DagTypeSubTypes/All/4000/1`;
        let urlSites = `${this.configEnv.apiServerUrl}Users/UserSiteAccess`;

        console.log('getUserSettings called..')

        let settings: UserSettings = JSON.parse(this.cache.fetchData(this.userSettingsKey));
      if (settings) {
            return Observable.of(settings);
        }

        return forkJoin([
            this.httpClient.get(urlAcccounts).map(res => res),
            this.httpClient.get(urlSites).map(res => res)
        ])
            .map((data: any[]) => {
                let accounts: Array<any> = data[0];
                let sites: number = data[1];
                let user: any = JSON.parse(this.cache.fetchData('msalUserInfo')); 
                let isInternal: boolean = !!user ? user.IsInternalUser : false;
                let accountList: Array<AccountSearch> = [];
                let permissionList: Array<Permissions> = [];

                if (accounts) {
                    //Sort Accounts alphabetically
                    accounts.sort((a, b) => a.ContextPointName !== b.ContextPointName ?
                        a.ContextPointName < b.ContextPointName ? -1 : 1 : 0);
                    //Populate accountList
                    accounts.forEach((r) => {
                        accountList.push(new AccountSearch(r.ContextPointId, r.ContextPointName));
                    });

                    var first = accounts[0];
                }

                if(user.Permissions) {
                    user.Permissions.forEach( item => {
                        permissionList.push(new Permissions(item));
                    })
              }

                var newSettings = new UserSettings({
                    DefaultContextPointId: first.ContextPointId,
                    DefaultContextPointName: first.ContextPointName,
                    IsInternalUser: isInternal,
                    IsStreetAccountUser: user.IsStreetAccountUser,
                    HasNalcoAccesUser: user.HasNalcoAccesUser,
                    Permissions: permissionList,
                    Accounts: accountList,
                    UserPreference: user.UserPreference,
                    Culture: user.Culture,
                    UserId: user.UserId
                });
                //newSettings.IsInternalUser = false; //Uncomment to test
                if (newSettings.IsInternalUser) {
                    newSettings.DefaultContextPointId = null;
                    newSettings.DefaultContextPointName = null;
                }

                if (sites > 0) {
                    newSettings.DefaultSiteId = sites.toString();
                    //newSettings.DefaultSiteId = '954970';
                    newSettings.IsSingleSiteUser = true;
                }

                this.setDefaultCache(newSettings);

                return newSettings;
            }
            );
    }

    private setDefaultCache(data: UserSettings) {
        this.cache.storeData(this.userSettingsKey, JSON.stringify(data));
        this.cache.storeData('defaultContextPointId', data.DefaultContextPointId);
        // this.cache.storeData('appliedfilterDetails', JSON.stringify(null), 'local');
    }

    getEffectiveContextPointId(): string {
        let contextPointId = this.cache.fetchData('defaultContextPointId');
        const details = JSON.parse(this.cache.fetchData('appliedfilterDetails', 'local'));
        if (details && details.contextPointId != null) {
            contextPointId = details.contextPointId;
        }
        return contextPointId;
    }

    saveFilterDetails(filterDetails) {
        this.cache.storeData('appliedfilterDetails', JSON.stringify(filterDetails), 'local');
    }

    saveSSDFilterDetails(filterDetails){
        this.cache.storeData('appliedSSDfilterDetails', JSON.stringify(filterDetails), 'local');
    }

    clearFilterDetails() {
        this.cache.clearData('appliedfilterDetails', 'local');
    }
    
    // saveUserRole(filterDetails) {
    //     this.cache.storeData(this.userRole, JSON.stringify(filterDetails));
    // }

    saveSSDSiteDetails(siteDetails){
        this.cache.clearData('SSDSiteDetails', 'local');
        this.cache.storeData('SSDSiteDetails', JSON.stringify(siteDetails), 'local');
    }

    getSSDSiteDetails(){
        return JSON.parse(this.cache.fetchData('SSDSiteDetails', 'local'));
    }

    getSSDFilterDetails(){
        return JSON.parse(this.cache.fetchData('appliedSSDfilterDetails', 'local'));
    }
    getFilterDetails() {
        return JSON.parse(this.cache.fetchData('appliedfilterDetails', 'local'));
    }

    getCurrentSettings(): UserSettings {
        return JSON.parse(this.cache.fetchData(this.userSettingsKey));
    }

    isInternalUser(): boolean {
        const user: UserSettings = JSON.parse(this.cache.fetchData(this.userSettingsKey));
        return user ? user.IsInternalUser : false;
    }

    isStreetAccountUser(): boolean{
        const user: UserSettings = JSON.parse(this.cache.fetchData(this.userSettingsKey));
        return user ? user.IsStreetAccountUser : false;
    }
    getUserRole() {
        const user: UserSettings = JSON.parse(this.cache.fetchData(this.userSettingsKey));
        if (user != null) {
            return user.Permissions;
        }
        else { return null; }
    }

    getUserCulture(): string {
        let userLanguage = 'en';
        const userSettings = this.getCurrentSettings();
        if (userSettings) {
            const userCulture = userSettings.Culture.match(/en|es|pt|zh|de|nl|fr|ru/);
            userLanguage = userCulture != null ? userCulture[0] : 'en';
        }
        return userLanguage;
    }

    public getLatestAnnouncements(userId): Observable<any> {
        let url = `${this.configEnv.apiServerUrl}api/v1/Announcement/GetLatestAnnouncements`+ '?userId=' + userId;
        return this.httpClient.get(url).map(response => {
          try {
            return response;
          } catch (e) {
            return response;
          }
        });
      }
    public saveAnnouncementStatusForUser(userId,announcementId) :Observable<any>{
        let url = `${this.configEnv.apiServerUrl}api/v1/Announcement/SaveAnnouncementStatusForUSer`+ '?userId=' + userId + '&announcementId=' + announcementId;
        let body:any;
        return this.httpClient.post(url,body).map(response => {
          try {
            return response;
          } catch (e) {
            return response;
          }
        });
    }
}

