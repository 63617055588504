import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LooperService } from './services/looper.service';
import { SharedMainService } from './services/shared-main.service';
import { SharedMainConfig } from './services/shared-main.service.config';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/footer/footer.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LeftMenuSubmenuItemComponent } from './components/navigation/left-menu-submenu-item/left-menu-submenu-item.component';
import { LeftMenuItemComponent } from './components/navigation/left-menu-item/left-menu-item.component';
import { LeftMenuComponent } from './components/navigation/left-menu/left-menu.component';
import { HumbergurMenuComponent } from './components/navigation/humbergur-menu/humbergur-menu.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { SubHeaderRightSectionComponent } from './components/sub-header/right-section/right-section.component';
import { SubHeaderMiddleSectionComponent } from './components/sub-header/middle-section/middle-section.component';
import { SubHeaderLeftSectionComponent } from './components/sub-header/left-section/left-section.component';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateICUParser } from 'ngx-translate-parser-plural-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    RouterModule,
    TranslateModule.forChild({
      parser: {
          provide: TranslateParser,
          useClass: TranslateICUParser
      }
  }),
  ],
  declarations: [BreadcrumbComponent,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    NavigationComponent,
    LeftMenuSubmenuItemComponent,
    LeftMenuItemComponent,
    LeftMenuComponent,
    HumbergurMenuComponent,
    SubHeaderComponent,
    SubHeaderRightSectionComponent,
    SubHeaderMiddleSectionComponent,
    SubHeaderLeftSectionComponent,
    DropdownComponent
  ],
  exports: [BreadcrumbComponent,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    NavigationComponent,
    LeftMenuSubmenuItemComponent,
    LeftMenuItemComponent,
    LeftMenuComponent,
    HumbergurMenuComponent,
    SubHeaderComponent,
    SubHeaderRightSectionComponent,
    SubHeaderMiddleSectionComponent,
    SubHeaderLeftSectionComponent,
    DropdownComponent
  ],
  providers: [LooperService, SharedMainConfig, SharedMainService]
})
export class SharedMainModule {
  private static count = 0;
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedMainModule,
            providers: [],
        };
    }
}
