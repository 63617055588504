import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import * as sharedContracts from '../../shared/contracts/_contracts';
import * as sharedModels from '../../shared/models/_models';
import { LocalCacheService } from '../../core/local-cache/local-cache.service';
import { AppEnvConfig } from '../../app.env.config';
import { of } from 'rxjs/observable/of';
import { SharedMainConfig } from './shared-main.service.config';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';


@Injectable()
export class SharedMainService {
    private configEnv: any;
    private usersnavigationmenu: sharedModels.Navigation;
    private assetMaptabSelection = new Subject<any>();
    $assetMaptabSelection = this.assetMaptabSelection.asObservable();

    constructor(
        private sharedServiceConfig: SharedMainConfig,
        private http: HttpClient,
        private config: AppEnvConfig,
        private localCacheService: LocalCacheService) {
        this.configEnv = sharedServiceConfig.getEnvironment();
        this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
        this.configEnv.jsonServerUrl = config.getEnv('jsonServerUrl');
        this.configEnv.apiWaterServerUrl = config.getEnv('apiWaterServerUrl');
        
    }

    private parse(url: string, params: string[] = []): string {
        let result: string = url;
        if (params.length > 0) {
            // tslint:disable-next-line:no-unused-variable
            result = url.replace(/\{(\d+)\}/g, function (match: string, current: string): string {
                return params[parseInt(current, 10)] !== undefined ? params[parseInt(current, 10)] : '';
            });
        }
        return result;
    }
    public getServiceCategoriesAndAssetTypes(serviceName: string,forceLoad : boolean=false) : Observable<Array<sharedModels.ServiceCategory>>{
        const text = this.localCacheService.fetchData(serviceName+'_categoriesAndAssets');
        if (text != null && text !== '' && !forceLoad)
            return of(JSON.parse(text));
        else {
            const hostname = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getServiceCategoriesAndAssetTypes}`;
            const url = `${hostname}/${serviceName}`;
            return this.http.get(url).map((response: any) => {
                this.localCacheService.storeData(serviceName+'_categoriesAndAssets',JSON.stringify(response));
                return response;
            })
        }
    }
    public getSiteDetails(siteId: string): Observable<any> {
        const params: any = {
            dateRange: '',
            indicatorType: ''
        };

        const url: string = this.parse(this.configEnv.jsonServerUrl + this.configEnv.apiUrl.getSiteCompliance, [siteId]);
        return this.http.get(url, params).map(response => {
            return response['result'];
        });
    }

    getAssetMeasurementsToCompare(siteId: string, assetIds: Array<string>): Observable<Array<sharedModels.MeasurementToCompare>> {
        const params: any = {};

        params.assetIds = assetIds.join(',');
        params.measurementCategoryId = '';

        const url = this.configEnv.jsonServerUrl + this.parse(this.configEnv.apiUrl.getMeasurementsToCompare, [siteId]);

        return this.http.get(url, { params: params }).map((response:any) => {
            const measurementsResponse = response;
            const measurements = new Array<sharedModels.MeasurementToCompare>();

            if (measurementsResponse.result && measurementsResponse.result.length > 0) {
                measurementsResponse.result.forEach(measurement => {
                    measurements.push(new sharedModels.MeasurementToCompare(measurement));
                });
            }
            return measurements;
        });
    }

    public getUsersNavigationMenu(useremailaddress: string): Observable<sharedModels.Navigation> {
        if (this.usersnavigationmenu) {
            return Observable.of(this.usersnavigationmenu);
        } else {
            const fileTypesFromStorage = this.localCacheService.fetchJson('usersNavigationMenu', 7, 'local');
            if (fileTypesFromStorage) {
                this.usersnavigationmenu = fileTypesFromStorage;
                return Observable.of(this.usersnavigationmenu);
            }
        }

        const params: any = {};
        const url = this.configEnv.jsonServerUrl + this.parse(this.configEnv.apiUrl.getUsersNavigationMenu, [useremailaddress]);
        return this.http.get(url, params).map(response => {
            this.usersnavigationmenu = response['result'];
            this.localCacheService.storeJson('usersNavigationMenu', this.usersnavigationmenu, 'local');
            return this.usersnavigationmenu;
        });
    }

    public getCustomerDivisionData(iD: number, filter: string): Observable<any> {
        const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDagAccountChildren}${filter}/${iD}`;
        return this.http.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getCustomerDivisionChild(iD: number, filter: string): Observable<any> {
        const url = `${this.configEnv.apiServerUrl}${this.configEnv.apiUrl.getDagParentEdgeChildren}${filter}/${iD}`;
        return this.http.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getSalerRegionDistrictTerritories(): Observable<any> {
        // const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagSalesRegionDistrictTerritories, ['NalcoWater']);
        // const url = this.parse(this.configEnv.apiUrl.getDagSalesRegionDistrictTerritories);
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagSalesRegionDistrictTerritories;
        return this.http.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getRegionCountries(): Observable<any> {
        // const url = this.parse(this.configEnv.apiServerUrl + this.configEnv.apiUrl.getRegionCountries, ['NalcoWater']);
        // const url = this.parse(this.configEnv.apiUrl.getDagRegionCountries);
        const url = this.configEnv.apiServerUrl + this.configEnv.apiUrl.getDagRegionCountries;
        return this.http.get(url).map(response => {
            try {
                return response;
            } catch (e) {
                return response;
            }
        });
    }

    public getProjectOverview(projectID: string, unitsOfMeasure: string): Observable<any> {

      const url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getProjectOverview}${projectID}?unitStandard=${unitsOfMeasure}`;
      return this.http.get(url)
        .map((response: any) => response);
    }

    public getCorporateSetting(cpId: string): Observable<any> {
      const url = `${this.configEnv.apiWaterServerUrl}${this.configEnv.apiUrl.getCorporateSetting}${cpId}`;
      return this.http.get(url)
        .map((response: any) => response);
    }

    public updateAssetMapTabSelection(value) {
        this.assetMaptabSelection.next(value);
    }
    
}
