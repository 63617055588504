import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterNavigation'
})
export class FilterNavigation implements PipeTransform {
    transform(role, data,isInternalUser): any {
        const navigationData = [];
        data.forEach(value => {
            // if (value.name === 'Navigation - Main - Dashboards') {
            if (value.subNavigation.length === 0 && value.role) {
                role.forEach(main => {
                    if (value && value.role && value.role.indexOf(main) !== -1) {
                        if (navigationData.indexOf(value) === -1) {
                            navigationData.push(value);
                        }
                    }
                });
            } else {
                // value.subNavigation = this.filterByRole(role, value.subNavigation);
                value.subNavigation = this.filterByRoles(role, value.subNavigation,isInternalUser);
                navigationData.push(value);
            }
            //}
        });
        return navigationData;
    }

    filterByRole(role, data) {
        const mod = [];
        role.forEach(value => {
            data.forEach(subNav => {
                if (value === subNav.role) {
                    mod.push(subNav);
                }
            });
        });
        return mod;
    }

    // filterByRole with Array of roles
    filterByRoles(permissions, subNavigation,isInternalUser) {
        const filteredNav = [];
        for (const subItem of subNavigation) {
            for (const role of subItem.role) {
                if (permissions && permissions.indexOf(role) !== -1) {
                    if(subItem.isVisible !== undefined && !isInternalUser){
                        if(subItem.isVisible){
                            filteredNav.push(subItem);  
                        }
                     }else{
                            filteredNav.push(subItem);  
                        }               
                    break;
                }
            }
        }
        return filteredNav;
    }
}
