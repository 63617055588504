import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { LocalCacheService } from '../local-cache/local-cache.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public cache: LocalCacheService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.cache.fetchData('MSALToken');
    request = request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'Content-Type':  'application/json'
      }
    });
    return next.handle(request);
  }
}

