import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ISubheaderLeftSection, ISubheaderMiddleSection, ISubheaderRightSection } from '../../shared/components/sub-header/sub-header.modal';
import { IDropdownEventType } from '../../shared/components/dropdown/dropdown-model';
import { LocalCacheService } from '../local-cache/local-cache.service';

@Injectable()
export class SubheaderHandlerService {

  constructor(private localCacheService: LocalCacheService) { }


  private leftSectionSource = new Subject<ISubheaderLeftSection>();
  private middleSectionSource = new Subject<ISubheaderMiddleSection>();
  private rightSectionSource = new Subject<ISubheaderRightSection>();
  private btnClickSource = new Subject<string>();
  private ddChangeSource = new Subject<IDropdownEventType>();
  private duration = 7;

  subheaderLeftSection$ = this.leftSectionSource.asObservable();
  subheaderMiddleSection$ = this.middleSectionSource.asObservable();
  subheaderRightSection$ = this.rightSectionSource.asObservable();
  btnClickInSubhead$ = this.btnClickSource.asObservable();
  ddChangeInSubhead$ = this.ddChangeSource.asObservable();

  updateLeftSection(newData: ISubheaderLeftSection) {
    this.leftSectionSource.next(newData);
  }

  updateMiddleSection(newData: ISubheaderMiddleSection) {
    this.middleSectionSource.next(newData);
  }

  updateRightSection(newData: ISubheaderRightSection) {
    this.rightSectionSource.next(newData);
  }

  btnClickInSubhead(which: string) {
    this.btnClickSource.next(which);
  }

  ddChangeInSubhead(which: IDropdownEventType) {
    this.ddChangeSource.next(which);
  }


  /************** Session Storage section ****************/
  private storeData(key: string, value: any) {
    this.localCacheService.storeData(key, value);
  }

  private fetchData(key: string) {
    this.localCacheService.fetchData(key);
    return this.localCacheService.fetchData(key);
  }
}
