import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ISubheaderMiddleSection } from '../sub-header.modal';
import { SharedMainService } from '../../../services/shared-main.service';

@Component({
    selector: 'nalco-subheader-middle-section',
    templateUrl: './middle-section.component.html',
    styleUrls: ['./middle-section.component.scss'] 
})
export class SubHeaderMiddleSectionComponent implements OnInit {
    @Input() details: ISubheaderMiddleSection;
    @Output() btnClick= new EventEmitter<string>();

    activeBtn = 'build';

    constructor(private sharedMainService: SharedMainService) {}

    ngOnInit() {
        this.sharedMainService.$assetMaptabSelection.subscribe(res => {
            this.btnClickInSubhead(res.path);
        });
    }

    btnClickInSubhead(which: string) {
        this.activeBtn = which;
        this.btnClick.emit(which);
    }
}
