import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes, Router, Event, NavigationStart, NavigationEnd, PreloadAllModules } from '@angular/router';
import { USERROLES } from './app.config';

import { LoginComponent } from './core/auth/login/login.component';
import { AuthenticationGuard } from './core/auth/guards/authentication-guard';
import { AuthorizationGuard } from './core/auth/guards/authorization-guard';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { PageAccessDeniedComponent } from './core/components/page-access-denied/page-access-denied.component';
import { UtilityComponent } from './shared-admin/components/utility/utility.component';
import { HomeComponent } from './core/components/home/home.component';
import { ApplicationDowntimeComponent } from './core/components/application-downtime/application-downtime.component';
import { FnbComponent } from './shared/components/fnb/fnb.component';

declare var ADRUM: any;

// BASE APP ROUTE
const APP_ROUTES: Routes = [
  // for default redirect never put prefix for pathMatch
  // it should be always full, so that it will apply for once
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
    pathMatch: 'full'
  },
  // { path: 'assettree', redirectTo: 'asset-tree', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  {
    // for MSAL gettting the token
    path: 'code', component: LoginComponent,
  },
  {
    path: 'asset-tree',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/asset-tree/asset-tree.module#AssetTreeModule',
    data: {
      // this will allow all the users who have access to just AH see the Asset Tree also
      // security needs to be implemented in AT screen to restrict
      origin: 'at',
      roles: [USERROLES.ASSETTREEADMIN, USERROLES.ASSETHARVESTERREAD, USERROLES.ASSETHARVESTERREADWRITE]
    }
  },
  {
    path: 'assetharvester',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/asset-tree/asset-tree.module#AssetTreeModule',
    data: {
      // this will allow all the users who have access to just AH see the Asset Tree also
      // security needs to be implemented in AT screen to restrict
      origin: 'ah',
      roles: [USERROLES.ASSETTREEADMIN, USERROLES.ASSETHARVESTERREAD, USERROLES.ASSETHARVESTERREADWRITE]
    }
  },
  {
    path: 'offline-data-upload',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/offline-data-upload/offline-data-upload.module#OfflineDataUploadModule',
    data: {
      breadcrumb: "Offline Data Upload",
      roles: [USERROLES.ENVISION4WEB], //Need to check,
      path: 'offline-data-upload'
    }
  },
  {
    path: 'assetharvester',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/asset-harvester/asset-harvester.module#AssetHarvesterModule',
    data: {
      roles: [USERROLES.ASSETHARVESTERREAD, USERROLES.ASSETHARVESTERREADWRITE]
    }
  },
  {
    path: 'water-safety',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/water-safety/water-safety.module#WaterSafetyModule',
    data: {
      breadcrumb: 'Enterprise Overview',
      roles: [USERROLES.WSIDASHBOARD]
    }
  },
  {
    path: 'quality',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/quality/quality.module#QualityModule',
    data: {
      breadcrumb: "Water Quality",
      roles: [USERROLES.WQDASHBOARD]
    }
  },
  // linked omni module.
  {
    path: 'omni',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/omni/omni.module#OmniModule',
    data: {
      breadcrumb: "Omni",
      path: 'omni',
      roles: [USERROLES.OMNIDASHBOARD, /*USERROLES.OMNIDASHBOARD*/]
    }
  },
  {
    path: 'ro-optimizer',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/ro-optimizer/ro-optimizer.module#RoOptimizerModule',
    data: {
      breadcrumb: "RO Optimizer",
      roles: [USERROLES.RODASHBOARD]
    }
  },
  {
    path: 'system-assurance/admin',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/system-assurance-admin/system-assurance-admin.module#SystemAssuranceAdminModule',
    data: {
      breadcrumb: "System Assurance",
      roles: [USERROLES.SAADMIN]
    }
  },
  {
    path: 'omni/admin',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/omni/admin/admin.module#AdminModule',
    //  component: FinancialImpactAdminComponent,
    //  pathMatch: 'prefix',
    data: {
      breadcrumb: "Omni Admin",
      path: 'omni/admin',
      roles: [USERROLES.OMNIADMIN, /*USERROLES.FIADMIN*/]
    }
  },
  {
    path: 'financial-impact',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/financial-impact/financial-impact.module#FinancialImpactModule',
    data: {
      breadcrumb: "Financial Impact",
      path: 'financial-impact',
      roles: [USERROLES.FIDASHBOARD]
    }
  },
  {
    path: 'financial-impact/admin',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/financial-impact/admin/admin.module#AdminModule',
    //  component: FinancialImpactAdminComponent,
    //  pathMatch: 'prefix',
    data: {
      breadcrumb: "Financial Impact Admin",
      path: 'financial-impact/admin',
      roles: [USERROLES.FIADMIN]
    }
  },
  {
    path: 'data-visualization-old',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/data-visualization/data-visualization.module#DataVisualizationModule',
    // data: {
    //   breadcrumb: 'Data Visualization-old',
    //   path: 'data-visualization',
    //   roles: [USERROLES.VISDASHBOARD]
    // }
  },
  {
    path: 'data-visualization',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/data-visualization-library/data-visualization-library.module#DataVisualizationLibraryModule',
    data: {
      breadcrumb: 'Data Visualization',
      path: 'data-visualization',
      roles: [USERROLES.VISDASHBOARD]
    }
  },
  {
    path: 'water-flow-intelligence',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/water-flow-intelligence/water-flow-intelligence.module#WaterFlowIntelligenceModule',
    data: {
      breadcrumb: 'Water Flow Intelligence',
      path: 'water-flow-intelligence',
      roles: [USERROLES.WFIDASHBOARD]
    }
  },
  {
    path: 'water-flow-intelligence/admin',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/water-flow-intelligence/admin/admin.module#AdminModule',
    data: {
      breadcrumb: 'Water flow Intelligence Admin',
      path: 'water-flow-intelligence/admin',
      roles: [USERROLES.WFISENSORMAPPING]
    }
  },
  {
    path: 'notifications-admin',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/notifications-admin/notifications-admin.module#NotificationsAdminModule',
    data: {
      breadcrumb: 'Notifications Admin',
      path: 'notifications-admin',
      roles: [USERROLES.NOTIFICATIONS_ADMIN_INTERNAL, USERROLES.NOTIFICATIONS_ADMIN_OWN, USERROLES.NOTIFICATIONS_CUSTOMER_FULL,
      USERROLES.NOTIFICATIONS_CUSTOMER_ADD]
    }
  },
  {
    path: 'controller-credentials',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/controller-credentials/controller-credentials.module#ControllerCredentialsModule',
    data: {
      breadcrumb: 'Controller Credentials',
      path: 'controller-credentials',
      roles: [USERROLES.NOTIFICATIONS_ADMIN_INTERNAL, USERROLES.NOTIFICATIONS_ADMIN_OWN, USERROLES.NOTIFICATIONS_CUSTOMER_FULL,
      USERROLES.NOTIFICATIONS_CUSTOMER_ADD]
    }
  },
  {
    path: 'action-log',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/action-log/action-log.module#ActionLogModule',
    data: {
      breadcrumb: 'Action and Recommendations',
      path: 'action-log',
      roles: [USERROLES.ENVISION4WEB]
    }
  },
  {
    path: 'my-documents',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/my-documents/my-documents.module#MyDocumentsModule',
    data: {
      breadcrumb: 'My Documents',
      path: 'my-documents',
      roles: [USERROLES.ENVISION4WEB]
    }
  },
  {
    path: 'digital-twin',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/digital-twin/digital-twin.module#DigitalTwinModule',
    data: {
      hideLayout: true
      // roles: [USERROLES.WQDASHBOARD,USERROLES.OMNIDASHBOARD]
    }
  },
  {
    // tslint:disable-next-line:max-line-length
    path: 'navigate-data-viz',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/navigate-data-viz/navigate-data-viz.module#NavigateDataVizModule',
    data: {
      breadcrumb: 'Data Visualization',
      path: 'navigate-data-viz',
      roles: [USERROLES.ENVISION4WEB]
    }
  },
  {
    // tslint:disable-next-line:max-line-length
    path: 'service-summary',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/service-summary/service-summary.module#ServiceSummaryModule',
    data: {
      breadcrumb: 'service-summary',
      path: 'service-summary',
      roles: [USERROLES.ENVISION4WEB]
    }
  },
  {
    // tslint:disable-next-line:max-line-length
    path: 'fnb',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {
      breadcrumb: 'F & B',
      path: 'fnb',
      roles: [USERROLES.ASSETTREEADMIN, USERROLES.ASSETHARVESTERREAD, USERROLES.ASSETHARVESTERREADWRITE]
    },
    component: FnbComponent
  },
  {
    path: 'common-pages',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/common-pages/common-pages.module#CommonPagesModule',
    data: {
      breadcrumb: 'Common Pages',
      path: 'common-pages',
      roles: [USERROLES.ENVISION4WEB]
    }
  },
  {
    path: 'scale-guard-index',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/scale-guard-index/sgi.module#ScaleGuardIndexModule',
    data: {
      breadcrumb: "Scale Guard Index",
      path: 'scale-guard-index',
      roles: [USERROLES.SGI_ADMIN_ACCESS, USERROLES.SGI_READ_ACCESS]
    }
  },
  {
    path: 'water-balance-intelligence',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/water-balance-intelligence/wbi.module#WBIModule',
    data: {
      breadcrumb: "Water Balance Intelligence",
      path: 'water-balance-intelligence',
      roles: [USERROLES.WBI_READ_ACCESS]
    }
  },
  {
    path: 'ssd-analyzer',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/ssd-analyzer/ssd-analyzer.module#SsdAnalyzerModule',
    data: {
      breadcrumb: 'SSD Analyzer',
      path: 'ssd-analyzer',
      roles: [USERROLES.ENVISION4WEB]
    }
  },
  {
    path: 'sample-number-mapping',
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    loadChildren: 'app/ssd-poc/ssd-poc.module#SsdPocModule',
    data: {
      breadcrumb: 'Sample Number Mapping',
      path: 'sample-number-mapping',
       roles: [USERROLES.ENVISION4WEB]
    }
  },
  {
    path: 'access-denied',
    component: PageAccessDeniedComponent,
    pathMatch: 'prefix'
  },
  {
    path: 'downtime',
    component: ApplicationDowntimeComponent,
    pathMatch: 'prefix'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    pathMatch: 'prefix'
  }
];

export const APP_ROUTER: ModuleWithProviders = RouterModule.forRoot(APP_ROUTES, {
  // preloadingStrategy: PreloadAllModules,
  useHash: true,
  // enableTracing: true
});

/*
export class AppRoutingModule {
  vpView: any;

  // Subscribe to the Router URL changes and report the metrics to AppDynamics.
  constructor(public router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        console.debug('NavigationEnd: ' + event.url);

        this.vpView.markViewChangeEnd();
        this.vpView.markViewDOMLoaded();
        this.vpView.markXhrRequestsCompleted();
        this.vpView.markViewResourcesLoaded();
        this.vpView.end();
        ADRUM.report(this.vpView);

      } else if (event instanceof NavigationStart) {
        console.debug('NavigationStart: ' + event.url);

        this.vpView = new ADRUM.events.VPageView();
        this.vpView.start();
        this.vpView.markViewChangeStart();
      }
    });
  };
}
*/
