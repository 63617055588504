import { AppDynamicsService } from './../../analytics/app-dynamics.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { LocalCacheService } from '../../local-cache/local-cache.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggerService } from '../../logger/global-logger.service';
import { User } from '../../../shared/models/User';
import { AppEnvConfig } from '../../../app.env.config';
import { TranslateService } from '@ngx-translate/core';
import { SubheaderHandlerService } from '../../nav/subheader-handler.service';
import { NavigationHandlerService } from './../../../core/nav/navigation-handler.service';
import { Subscription } from 'rxjs';
import { UserService } from './../../../core/user/user.service';
import { FilterNavigation } from './../../../core/pipes/filter-navigation.pipe';
import { Navigation } from '../../../shared/models/Navigation';
import { INavigationMenu } from '../../../shared/contracts/_contracts';
import { Response } from '../../../shared/components/navigation/navigation.json';
import { environment } from '../../../../environments/environment';
import { MsalService } from '../../msal/msal.service';

@Component({
  selector: 'nalco-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user: User;
  authenticated: Boolean = false;
  navigationMenu: Navigation;
  leftNavigationItems: INavigationMenu[];
  permissions = [];
  returnUrl: string;
  subheaderData = {
    leftSection: {
      type: '',
      data: '',
      className: 'col-lg-6'
    },
    middleSection: {
      type: '',
      data: [],
      className: ''
    },
    rightSection: {
      type: '',
      data: [],
      className: ''
    }
  };

  constructor(
    private logger: LoggerService,
    private route: ActivatedRoute,
    private router: Router,
    private cache: LocalCacheService,
    private appDynamicsService: AppDynamicsService,
    private config: AppEnvConfig,
    private translation: TranslateService,
    private subHeaderService: SubheaderHandlerService,
    private navService: NavigationHandlerService,
    private msalService: MsalService,
    private userService: UserService
  ) {
    this.subheaderData.leftSection.data = '';
    this.subHeaderService.updateLeftSection(this.subheaderData.leftSection);
    this.subHeaderService.updateMiddleSection(this.subheaderData.middleSection);
    this.subHeaderService.updateRightSection(this.subheaderData.rightSection);
    this.cache.storeData('adrumService', 'Home', 'local');
    this.cache.storeData('adrumHeader', '', 'local');
    this.cache.storeData('adrumComponent', "HomeComponent", 'local');
    this.cache.storeData('adrumElement', '', 'local');
    this.cache.storeData('sitename', '', 'local');
  }




  ngOnInit() {

    // not sending query param from guard, now using cache
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.logger.info({ message: 'login component init' }, false, true);
    this.cache.clearData('sitename', 'local');
    this.cache.clearData('corporateAccountName', 'local');
    this.cache.clearData('navigateDV', 'local');
    this.getUserSettings();
   
    if (this.msalService.userInfo) {
      this.returnUrl = this.returnUrl || this.cache.fetchData('returnUrl', 'local');
      // allow redirect from login only for the first time after receiving id token
      if (this.returnUrl && this.returnUrl.length > 1) {
        console.log('ready to redirect', this.returnUrl);
        //this.router.navigateByUrl(decodeURIComponent(this.returnUrl));
      }
    } else {
      console.log('ready to redirect', this.returnUrl);
      // this.msalService.login();
      /*
      this.cache.storeData('returnUrl', this.returnUrl, 'local');
     */
    }
  }


  filterByUserRole(role, navigationDetail) {
    const userSettings = this.userService.getCurrentSettings();
    let navigationMenu = [];
    if (role !== null) {
        const pipe = new FilterNavigation();
        navigationMenu = pipe.transform(role, navigationDetail.leftNavigation,userSettings.IsInternalUser);
        return navigationMenu;
    } else {
        return navigationMenu;
    }
  }

  getUserSettings() {
    const userSettings = this.userService.getCurrentSettings();
    if (userSettings.Permissions) {
      this.permissions = userSettings.Permissions;
    }
    // const userCulture = userSettings.Culture.match(/en|es|pt|zh|de|nl|fr|ru/);
    // const userLanguage = userCulture != null ? userCulture[0] : 'en';

    const role = this.userService.getUserRole();
    const userPermissions = [];
    if (role !== null) {
        role.forEach(function (s) {
            userPermissions.push(s.PermissionId.toString());
        });
    }

    const fullMenu = JSON.parse(JSON.stringify(Response));
    this.navigationMenu = fullMenu;
    this.navigationMenu.leftNavigation = this.filterByUserRole(userPermissions, fullMenu);
    // this.translation.use(userLanguage);
  }

  navigateToExternal(navigationItem: any){
    if (navigationItem.breadcrumb === 'CIP') {
      window.open(environment.CIP_LINK);
    }
    else if (navigationItem.breadcrumb === 'eCommerce') {
      window.open(environment.E_Commerce);
    }
  }

  /************ Analytics Listener ************/
  @HostListener('click', ['$event'])
  onClick(event) {
    const adrumEvent = event;
    const adrumService = 'Home';
    const adrumComponent = this.constructor.name.toString();
    this.appDynamicsService.adrumClickEvent(adrumEvent, adrumService, adrumComponent);
  }

}
