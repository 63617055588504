/// <reference path="../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { Router, RouterModule, ɵROUTER_PROVIDERS, PreloadAllModules } from '@angular/router';
import { DndModule } from 'ng2-dnd';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2Webstorage } from 'ngx-webstorage';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import highstock from 'highcharts/modules/stock.src';
import annotations from 'highcharts/modules/annotations.src';
import { AuthenticationGuard } from './core/auth/guards/authentication-guard';
import { QualityGuard } from './core/auth/guards/quality-guard';

import { AuthorizationGuard } from './core/auth/guards/authorization-guard';
import { AuthorizationService } from './core/auth/guards/authorization.service';
import { LoginComponent } from './core/auth/login/login.component';

import { SubheaderHandlerService } from './core/nav/subheader-handler.service';
import { headerHandlerService } from './core/nav/header-handler.service';
import { NavigationHandlerService } from './core/nav/navigation-handler.service';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpHeaders, HttpRequest } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService, TranslateModuleConfig, TranslateParser } from '@ngx-translate/core';
import { TranslateICUParser } from 'ngx-translate-parser-plural-select';
import { parseStringTemplate } from 'string-template-parser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerService } from './core/logger/global-logger.service';
import { LoggerComponent } from './core/logger/logger.component';
// import { ToasterModule } from 'angular2-toaster';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { APP_ROUTER } from './app.routes';
// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// optional, provides moment-style pipes for date formatting
import { MomentModule } from 'angular2-moment';
import { SimpleTimer } from 'ng2-simple-timer';
import { LocalCacheModule } from './core/local-cache/local-cache.module';
import { LocalCacheService } from './core/local-cache/local-cache.service';
import { AppEnvConfig } from './app.env.config';
// import { HttpInterctor } from './core/auth/http.interceptor';
import { HttpClientInterceptor } from './core/auth/httpClient.interceptor';
import { UserService } from './core/user/user.service';
import { SiteService } from './shared/services/site.service';
import { AppDynamicsService } from './core/analytics/app-dynamics.service';
import { HomeComponent } from './core/components/home/home.component';
import { PageAccessDeniedComponent } from './core/components/page-access-denied/page-access-denied.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import * as more from 'highcharts/highcharts-more.src';
// import exporting from 'highcharts/modules/exporting.src';
// import stock from 'highcharts/modules/stock.src';
import * as histogram from 'highcharts/modules/histogram-bellcurve';
import { ApplicationDowntimeComponent } from './core/components/application-downtime/application-downtime.component';
import { AssetTreeGuard } from './core/auth/guards/asset-tree-guard';
import { FilterNavigation } from './core/pipes/filter-navigation.pipe';
import { SharedMainModule } from './shared-main/shared-main.module';
import * as Highcharts from 'highcharts';
import * as highchartsSankey from 'highcharts/modules/sankey.src';
import * as highchartsBoost  from 'highcharts/modules/boost.src'
import { MsalService } from './core/msal/msal.service';
import { FnbComponent } from './shared/components/fnb/fnb.component';
import { TokenInterceptor } from './core/auth/token.interceptor';
import { AccountDetailsComponent } from './shared/components/account-details/account-details.component';
import { ErrorHandlerComponent } from './data-visualization/error-handler/error-handler.component';
import { ErrorhandlingPopupComponent } from './shared/components/error-handling/errorhandling-popup/errorhandling-popup.component';
highchartsSankey(Highcharts);
highchartsBoost(Highcharts);

// ***** section for all factories ******
export function createTranslateLoader(http: HttpClient) {
  const headers = new HttpHeaders();
  headers.set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
  headers.set('Pragma', 'no-cache');
  headers.set('Expires', '0');
  http.options('', {headers: headers});
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

export function loadConfig(config: AppEnvConfig) {
  return () => config.load();
}

export function highchartsModules() {
  // apply Highstock Modules to this array
  return [more, highchartsSankey, highchartsBoost, histogram];
  }

/*export function highchartsModules() {
  // apply Highstock Modules to this array
  return [ highstock ,  annotations ];
  }*/

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoggerComponent,
    HomeComponent,
    PageAccessDeniedComponent,
    PageNotFoundComponent,
    FilterNavigation,
    ApplicationDowntimeComponent,
    FnbComponent,
    ErrorHandlerComponent,
    ErrorhandlingPopupComponent
  ],
  exports: [
    PageNotFoundComponent,
    PageAccessDeniedComponent,
    ErrorhandlingPopupComponent
  ],
  imports: [
    // RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadAllModules, useHash: true}),
    APP_ROUTER,
    SharedMainModule.forRoot(),
    // SharedAdminModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    DndModule.forRoot(),
    NgbModule.forRoot(),
//    ToasterModule,
    ToastrModule.forRoot(), // ToastrModule added
    Ng2Webstorage.forRoot({ prefix: 'nalco', separator: '.', caseSensitive: true }),
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    LocalCacheModule.forRoot({
      mode: 'session',
      namespace: 'ecolab.envision_'
    }),
  ],
  providers: [
    DatePipe,
    SimpleTimer,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: HIGHCHARTS_MODULES, useFactory: () => [more, highchartsSankey] },
    {provide:  HIGHCHARTS_MODULES , useFactory: highchartsModules},
    AppEnvConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: (loadConfig),
      deps: [AppEnvConfig],
      multi: true
    },
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      //useClass: HttpClientInterceptor,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthenticationGuard,
    QualityGuard,
    AssetTreeGuard,
    AuthorizationGuard,
    AuthorizationService,
    SubheaderHandlerService,
    headerHandlerService,
    NavigationHandlerService,
    LoggerService,
    LocalCacheService,
    UserService,
    MsalService,
    SiteService,
    AppDynamicsService
  ],
  entryComponents: [ErrorHandlerComponent, ErrorhandlingPopupComponent],

  bootstrap: [AppComponent]
})
export class AppModule { }


