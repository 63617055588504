import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ENVIRONMENTS, APP_CONFIG } from './app.config';
import 'rxjs/add/operator/catch';

@Injectable()
export class AppEnvConfig {

    private config: Object = null;
    private env: Object = null;

    constructor(private http: HttpClient) {

    }

    public getEnv(key: any) {
        return this.env[key];
    }


    public load() {
        return new Promise((resolve, reject) => {
           // alert(1);
            this.http.get('env.json').map(res => res)
                .catch((error: any): any => {
                  //  alert(2);
                    console.log('Configuration file "env.json" could not be read');
                    resolve(true);
                    return Observable.throw(error.json().error || 'Server error');
                }).subscribe((envResponse) => {
                   // alert(3);
                    this.env = envResponse;
                 //   debugger;
                    //console.log('Environment is ready', this.env);
                    resolve(true);
                });
        });
    }
}
