// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  environment: 'DEV',
  timeoutSession: 3300,
  timeoutIdle: 300,
  enableProdMode:false,
  enableDebug: true,
  userVoiceConfig: {
    apiKey: 'U7Hxo2lFenmmxjeusxu4JA',
    forumID: {
      WQ: '924601',
      WSI: '924601',
      AT: '924601',
      DV: '924601',
      FI: '924601',
      OMNI: '924601',
      WFI: '924601',
      AR : '924601',
      AH : '924601',
      SSD : '924601',
      MYDOC : '924601'
    }
  }
 ,
  Unilever_WQId: '4081993',
  Unilever_WQDuration: '365',
  Unilever_OMNIId: '4081993',
  Unilever_OMNIDuration: '365',
  CIP_LINK: 'https://3dtrasarcip-qa.ecolab.com/Default.aspx',
  Envision_EData : 'https://stg-automation.nalco.com/envision/mde/',
  Envision_ERB : 'https://stg-automation.nalco.com/envision/Reporting/',
  Envision_ALARMS : 'https://stg-envision.nalco.com/_layouts/envision/WAAlarmsAndNotifications.aspx',
  Envision_CONTROLLER : 'https://stg-envision.nalco.com/_layouts/enVision/ControllerManagement.aspx',
  Envision_CONFIGURATOR : 'https://stg-3dtrasarcontroller.nalco.com/Configurator/',
  E_Commerce : 'https://connect.ecolab.com'
};

