import { Component, OnInit, Input, Output, EventEmitter, ApplicationRef, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as contracts from '../../../contracts/_contracts';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'nalco-errorhandling-popup',
  templateUrl: './errorhandling-popup.component.html',
  styleUrls: ['./errorhandling-popup.component.scss']
})
export class ErrorhandlingPopupComponent implements OnInit {

  @Input() errorMessage: string;
  disablebtn: boolean;

  constructor(public activeModal: NgbActiveModal, public changeRef: ChangeDetectorRef) {
  }

  ngOnInit() { }

  closeModal() {
    this.activeModal.close();
  }

  /* To copy any Text */
  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
