//The purpose of the SiteService class is to encapsulate the API methods under the Site tag:
//https://oip-plt-wps-webapi-001-dq-dev.azurewebsites.net/swagger/ui/index#!/Site
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AppEnvConfig } from '../../app.env.config';
import { IDAGSites } from '../contracts/IDAGSites';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SiteService {
    filterData = new EventEmitter<any>();
    private configEnv: any = {};
    constructor(
        private config: AppEnvConfig,
        private httpClient: HttpClient) {
        this.configEnv.apiServerUrl = config.getEnv('apiServerUrl');
    }
    private defaultSite:IDAGSites = {};
    private defaultCustomerArea = 0;
    private defaultCustomerAreaAssets  = [];
    private isExportPDFClicked = false;
    // Observable  source
    private _filterSource = new BehaviorSubject(this.defaultSite);
    private _customerArea = new BehaviorSubject(this.defaultCustomerArea);
    private _customerAreaAssets = new BehaviorSubject(this.defaultCustomerAreaAssets);
    // Observable stream
    public filterSite$ = this._filterSource.asObservable();
    public customerArea$ = this._customerArea.asObservable();
    public customerAreaAssets$ = this._customerAreaAssets.asObservable();

    public getCustomerArea() {
        return this._customerArea.value;
    }

    public setCustomerArea(ca) {
        this._customerArea.next(ca);
    }

    public getCustomerAreaAssets() {
        return this._customerAreaAssets.value;
    }

    public setCustomerAreaAssets(assets) {
        this._customerAreaAssets.next(assets);
    }

    public getFilterSite() {
        return this._filterSource;
    }

    public setFilterSite(site) {
        this._filterSource.next(site);
    }

    getSitesList(searchString: string, serviceName: string = '', searchIds: number = 0): Observable<IDAGSites[]> {
        let urlSites = `${this.configEnv.apiServerUrl}DagSites`;
        let params: any = {
            key: 'NalcoWater',
            value: searchString
        }
        if (serviceName.toLowerCase() === 'ah' || serviceName.toLowerCase() === 'roo') {
            urlSites = `${this.configEnv.apiServerUrl}SitePagingByUser`;
            params = {
                Dag: "NalcoWater",
                PageIndex: 1,
                PageSize: 50,
                SearchString: searchString,
                SearchIds: [
                    searchIds
                ]
            };
        } else if (serviceName.toLowerCase() === 'ss') {
            urlSites = `${this.configEnv.apiServerUrl}CustomerSites?searchString=` + searchString;
            params = [searchIds];
        }

        return this.httpClient.post(urlSites, params).map((res :any) => res);
    }
}

