import { environment } from './../../../../../environments/environment';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppEnvConfig } from '../../../../app.env.config';

@Component({
  selector: 'nalco-left-menu-submenu-item',
  templateUrl: './left-menu-submenu-item.component.html'
})
export class LeftMenuSubmenuItemComponent implements OnInit {
  @Input() details: any;
  @Output() submenuItemClick: EventEmitter<any> = new EventEmitter();
  public forumID: any;
  public isB2CEnable: string;
  public environment: string;

  constructor(
    private config: AppEnvConfig
  ) { 
    this.isB2CEnable = config.getEnv('IsB2CEnable').toString();
    this.environment = config.getEnv('environment').toString();
  }

  ngOnInit() {
    this.forumID = environment.userVoiceConfig.forumID;
  }

  menuItemClick() {

    // Check if the link is for training and uservoice
    if (this.details.link === 'Training-Customers-External') {
      if (this.environment === 'STG' && this.isB2CEnable === 'true') {
        window.open('https://learning.cds.ecolab.com/staging/auth/azureb2c/');
      } else if (this.environment === 'prod' && this.isB2CEnable === 'true') {
        window.open('https://learning.cds.ecolab.com/lms/auth/azureb2c/');
      } else {
        window.open('http://40.70.21.254/lms/');
      }
    } else if (this.details.link === 'Training-Ecolab-Associate') {
      window.open('https://app.powerbi.com/groups/me/apps/65edf861-e5b1-442a-b4bb-9242d4673875/reports/ccf173c8-69f8-4fc7-9f2c-9d870bcdb6d0/ReportSection');
    } else if (this.details.link === 'Water-Quality-Uservoice') {                                      // check if the link is for Uservoice
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WQ + '-water-quality');
    } else if (this.details.link === 'Water-Safety-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WSI + '-water-safety-intelligence');
    } else if (this.details.link === 'financial-impact-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.FI + '-financial-impact');
    } else if (this.details.link === 'data-Visualization-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.DV + '-data-visualization');
    } else if (this.details.link === 'water-Flow-intelligence-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.WFI + '-water-flow-intelligence');
    } else if (this.details.link === 'service-summary-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.SSD + '-service-summary-dashboard');
    } else if (this.details.link === 'Action-Recommendation-Uservoice'){
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.AR+ '-ecolab3d-actions-recommendations');
    } else if(this.details.link === 'Asset-Harvester-Uservoice'){
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.AH+ '-asset-harvester');
    } else if(this.details.link === 'Omni-Uservoice'){
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.OMNI+ '-omni');
    } else if (this.details.link === 'my-documents-Uservoice') {
      window.open('https://nalcowater.uservoice.com/forums/' + this.forumID.MYDOC+ '-my-documents');
    } else if (this.details && this.details.isExternal && this.details.breadcrumb === 'CIP') {
      window.open(environment.CIP_LINK);
    } else if (this.details && this.details.isExternal && this.details.breadcrumb === 'eCommerce') {
      window.open(environment.E_Commerce);
    } else if (this.details.link === 'Envision-Edata') {
       window.open(environment.Envision_EData);
    } else if (this.details.link === 'Envision-ERB') {
       window.open(environment.Envision_ERB);
    } else if (this.details.link === 'Envision-Alarms') {
       window.open(environment.Envision_ALARMS);
    } else if (this.details.link === 'Envision-Controller') {
       window.open(environment.Envision_CONTROLLER);
    } else if (this.details.link === 'Envision-Configurator') {
       window.open(environment.Envision_CONFIGURATOR);
    } else {
      this.submenuItemClick.emit({ link: this.details.link });
    }
  }
}
