import { Injectable, Output } from '@angular/core';
import { UserService } from '../../user/user.service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  Router,
  CanActivateChild
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from './authorization.service';

@Injectable()
export class AuthorizationGuard implements CanActivate, CanActivateChild {

  constructor(
    private authorizationService: AuthorizationService,
    private router: Router, private userService: UserService
  ) {console.log("Authentication guard called"); }

  canActivate(

    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles = next.data.roles;
    //this.userService.saveUserRole(allowedRoles);
    const isAuthorized = this.authorizationService.isAuthorized(allowedRoles);
    if (!isAuthorized) {
      this.router.navigate(['/access-denied']);
    }
    return isAuthorized;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles = next.data.roles;
    const isAuthorized = this.authorizationService.isAuthorized(allowedRoles);
    if (!isAuthorized) {
      this.router.navigate(['/access-denied']);
    }
    return isAuthorized;
  }
}
