import { Component, OnInit } from '@angular/core';
import { SubheaderHandlerService } from '../../nav/subheader-handler.service';

@Component({
  selector: 'nalco-page-access-denied',
  templateUrl: './page-access-denied.component.html',
  styleUrls: ['./page-access-denied.component.scss']
})
export class PageAccessDeniedComponent implements OnInit {

  subheaderData = {
    leftSection: {
      type: '',
      data: '',
      className: 'col-lg-6'
    },
    middleSection: {
      type: '',
      data: [],
      className: ''
    },
    rightSection: {
      type: '',
      data: [],
      className: ''
    }
  };

  constructor(  private subHeaderService: SubheaderHandlerService) {
    this.subheaderData.leftSection.data = '';
    this.subHeaderService.updateLeftSection(this.subheaderData.leftSection);
    this.subHeaderService.updateMiddleSection(this.subheaderData.middleSection);
    this.subHeaderService.updateRightSection(this.subheaderData.rightSection);
   }

  ngOnInit() {
  }

}
