import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'nalco-left-menu-item',
  templateUrl: './left-menu-item.component.html'

})
export class LeftMenuItemComponent implements OnInit {

  showMenu = true;
  @Input() id: number;
  @Input() text: string;
  @Input() heading: string;
  @Input() link: string;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() subMenu: any;
  // additional property to set any additional feature for menu
  // for RK it is for feedback floating down
  @Input() itemContainerClass: string;
  @Output() menuItemClick: EventEmitter<any> = new EventEmitter();
  siteId: string;
  toggle = false;
  constructor(private router: Router,
    private translate: TranslateService,
     private route: ActivatedRoute) {
  }

  ngOnInit() {

  }

  @HostListener('document:click', ['$event']) clickedOutside(event) {
     if ( event.target.className.indexOf  !==  undefined
       && event.target.className.indexOf('menu-text')  <  0 ) {
      this.showMenu = false;
    }
  }

  public itemClicked() {
    if (this.subMenu !== undefined && this.subMenu.length === 0) {
      this.menuItemClick.emit({ link: this.link });
    } else {
      this.showMenu = !this.showMenu;
    }
  }

  public subMenuItemClick(event: any) {
    if (window.location.href.search('/sites/') !== -1) {
      const splitUrl = window.location.href.split('/');
      if (splitUrl.length > 5) {
        this.siteId = splitUrl[5];
      }
    } else {
      this.siteId = null;
    }

    this.menuItemClick.emit({ link: event.link, siteId: this.siteId });
  }
}
