export const Response = {

  'leftNavigation': [
    {
      'name': 'Navigation - Main - Dashboards',
      'link': '/sites',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.MAIN_NAV_LABEL',
      'hover': '',
      'breadcrumb': 'Dashboards',
      'icon': 'icon-icon_dashboard',
      'text': 'LEFT_NAV.MAIN_NAV_LABEL',
      'subNavigation': [
        {
          'name': 'Navigation - Main - Water Safety',
          'link': '/water-safety',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL',
          'hover': 'Water Safety Intelligence',
          'breadcrumb': 'Water Safety Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['2']
        },
        {
          'name': 'Navigation - Main - Water Quality ',
          'link': '/quality',
          'labelMobile': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL',
          'hover': ' Water Quality ',
          'breadcrumb': 'Water Quality',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['4']
        },
        {
          'name': 'Navigation - Main - OMNI',
          'link': '/omni',
          'labelMobile': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL',
          'hover': 'OMNI',
          'breadcrumb': 'OMNI',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['5']
        },
        {
          'name': 'Navigation - Main - Financial Impact',
          'link': '/financial-impact',
          'labelMobile': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL',
          'hover': 'Financial Impact',
          'breadcrumb': 'Financial Impact',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['8']
        },
        {
          'name': 'Navigation - Main - Data Visualization',
          'link': '/data-visualization',
          'labelMobile': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL',
          'hover': 'Data Visualization',
          'breadcrumb': 'Data Visualization',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['9']
        },
        {
          'name': 'Navigation - Main - Water Flow Intelligence',
          'link': '/water-flow-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL',
          'hover': 'Water Flow Intelligence',
          'breadcrumb': 'Water Flow Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['7']
        },
        {
          'name': 'Navigation - Main - CIP',
          'link': '',
          'labelMobile': 'LEFT_NAV.SUBNAV_CIP.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_CIP.NAV_LABEL',
          'hover': 'CIP',
          'isExternal': 'true',
          'breadcrumb': 'CIP',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['45']
        },
        {
          'name': 'Navigation - Main - Asset Harvester',
          'link': '/assetharvester',
          'labelMobile': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL',
          'hover': 'Asset Harvester',
          'breadcrumb': 'Asset Harvester',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['28', '29']
        },
        {
          'name': 'Navigation - Main - My Documents',
          'link': '/my-documents',
          'labelMobile': 'LEFT_NAV.SUBNAV_MY_DOCUMENT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_MY_DOCUMENT.NAV_LABEL',
          'hover': 'My Documents',
          'breadcrumb': 'My Documents',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['1','28', '29']
        }, 
        {
          'name': 'Navigation - Main - Scale Guard Index',
          'link': '/scale-guard-index',
          'labelMobile': 'LEFT_NAV.SUBNAV_SCALE_GUARD_INDEX.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SCALE_GUARD_INDEX.NAV_LABEL',
          'hover': 'Scale Guard Index',
          'breadcrumb': 'Scale Guard Index',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['53', '54']
        },
        {
          'name': 'Navigation - Main - Water Balance Intelligence',
          'link': '/water-balance-intelligence',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_BALANCE_INTELLIGENCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_BALANCE_INTELLIGENCE.NAV_LABEL',
          'hover': 'Water Balance Intelligence',
          'breadcrumb': 'Water Balance Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['52']
        },
        {
          'name': 'Navigation - Main - eCommerce',
          'link': '',
          'labelMobile': 'LEFT_NAV.SUBNAV_ECOMMERCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_ECOMMERCE.NAV_LABEL',
          'hover': 'eCommerce',
          'isExternal': 'true',
          'breadcrumb': 'eCommerce',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Navigation - Main - RO Optimizer',
          'link': '/ro-optimizer',
          'labelMobile': 'LEFT_NAV.SUBNAV_RO_OPTIMIZER.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_RO_OPTIMIZER.NAV_LABEL',
          'hover': 'RO Optimizer',
          'breadcrumb': 'RO Optimizer',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['59']
        },
      ]
    },
    {
      'name': 'Navigation - Main - Admin & Settings',
      'link': '/settings',
      'labelMobile': 'LEFT_NAV.ADMIN_NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.ADMIN_NAV_LABEL',
      'hover': '',
      'breadcrumb': 'Settings',
      'icon': 'ico-nav-settings2',
      'text': 'LEFT_NAV.ADMIN_NAV_LABEL',
      'subNavigation': [
        {
          'name': 'Navigation - Main - Admin & Settings - Asset Tree',
          'link': '/asset-tree',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_ASSET_TREE.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_ASSET_TREE.ADMIN_NAV_LABEL',
          'hover': 'Asset Tree',
          'breadcrumb': 'Asset Tree',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['3']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Water Safety ',
          'link': '/water-safety/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_WATER_SAFETY.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_WATER_SAFETY.ADMIN_NAV_LABEL',
          'hover': 'Water Safety Admin',
          'breadcrumb': 'Water Safety Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['10']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Water Safety Legislative ',
          'link': '/water-safety/admin/brt',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_WATER_SAFETY.ADMIN_NAV_LABEL_LEGISLATIVE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_WATER_SAFETY.ADMIN_NAV_LABEL_LEGISLATIVE',
          'hover': 'Water Safety Legislative Admin',
          'breadcrumb': 'Water Safety Legislative Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['51']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Quality ',
          'link': '/quality/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_QUALITY.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_QUALITY.ADMIN_NAV_LABEL',
          'hover': 'Water Quality Admin',
          'breadcrumb': 'Water Quality Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['11']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - OMNI ',
          'link': '/omni/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_OMNI.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_OMNI.ADMIN_NAV_LABEL',
          'hover': 'OMNI Admin',
          'breadcrumb': 'OMNI Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['12']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Financial Impact',
          'link': '/financial-impact/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_FINANCIAL_IMPACT.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_FINANCIAL_IMPACT.ADMIN_NAV_LABEL',
          'hover': 'Financial Impact Admin',
          'breadcrumb': 'Financial Impact Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['15']
        },
        // 157371 - Disable notification admin  - undo changes of 157371
        {
          'name': 'Navigation - Main - Admin & Settings - Notifications',
          'link': '/notifications-admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_NOTIFICATIONS.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_NOTIFICATIONS.ADMIN_NAV_LABEL',
          'hover': 'Notifications Admin',
          'breadcrumb': 'Notifications Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['19', '20', '21', '22']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Water Flow Intelligence',
          'link': '/water-flow-intelligence/admin/sensor-config',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_WFI_SENSOR_CONFIG.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_WFI_SENSOR_CONFIG.ADMIN_NAV_LABEL',
          'hover': 'WFI Sensor Mapping',
          'breadcrumb': 'WFI Sensor Mapping',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['27']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Offline Data Upload',
          'link': '/offline-data-upload',
          'labelMobile': 'OFFLINE_DATA_UPLOAD.OFFLINE_DATA',
          'label': 'OFFLINE_DATA_UPLOAD.OFFLINE_DATA',
          'hover': 'Offline Data Upload',
          'breadcrumb': 'Offline Data Upload',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - Controller Credentials',
          'link': '/controller-credentials',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_CONTROLLER_CREDENTIALS.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_CONTROLLER_CREDENTIALS.ADMIN_NAV_LABEL',
          'hover': 'Controller Credentials',
          'breadcrumb': 'Controller Credentials',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Navigation - Main - Admin & Settings - System Assurance Admin',
          'link': '/system-assurance/admin',
          'labelMobile': 'LEFT_NAV.ADMIN_SUBNAV_SYSTEM_ASSURANCE.ADMIN_NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.ADMIN_SUBNAV_SYSTEM_ASSURANCE.ADMIN_NAV_LABEL',
          'hover': 'System Assurance Admin',
          'breadcrumb': 'System Assurance Admin',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['55']
        }
      ]
    },
    {
      'name': 'Navigation - Main - Action Log',
      'link': '/action-log',
      'labelMobile': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'label': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'hover': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'breadcrumb': 'Actions Log',
      'text': 'ACTION_LOG.HEADER.ACTION_LOG_REC',
      'icon': 'actionLogIcon',
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Navigation - Main - Service Summary',
      'link': '/service-summary',
      'labelMobile': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL_MOBILE',
      'label': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL',
      'hover': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL',
      'breadcrumb': 'Service Summary',
      'text': 'LEFT_NAV.SUBNAV_SERVICE_SUMMARY.NAV_LABEL_MOBILE',
      'icon': 'icon_Service_Summary',
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Training',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_TRAINING',
      'label': 'LEFT_NAV.MAIN_NAV_TRAINING',
      'hover': '',
      'breadcrumb': 'Training',
      'text': 'LEFT_NAV.MAIN_NAV_TRAINING',
      'icon': 'icon-TrainingIcon',
      'subNavigation': [
        {
          'name': 'Training Customers',
          'link': 'Training-Customers-External',
          'labelMobile': 'LEFT_NAV.CUSTOMERS',
          'label': 'LEFT_NAV.CUSTOMERS',
          'hover': '',
          'breadcrumb': 'Training Customers',
          'icon': '',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Training Ecolab Associate',
          'link': 'Training-Ecolab-Associate',
          'labelMobile': 'LEFT_NAV.ECOLAB_ASSOCIATE',
          'label': 'LEFT_NAV.ECOLAB_ASSOCIATE',
          'hover': ' Ecolab Associate ',
          'breadcrumb': 'Ecolab Associate ',
          'icon': '',
          'subNavigation': [],
          'role': ['1']
        }
      ]
    },
    // Feedback forums sub navigation links are sorted in ascending order.
    {
      'name': 'Feedback - Uservoice',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_USERFEEDBACK',
      'label': 'LEFT_NAV.MAIN_NAV_USERFEEDBACK',
      'hover': '',
      'breadcrumb': 'Feedback - Uservoice',
      'text': 'LEFT_NAV.MAIN_NAV_USERFEEDBACK',
      'icon': 'ico-nav-user-voice',
      'subNavigation': [
        {
          'name': 'Actions & Recommendations',
          'link': 'Action-Recommendation-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_ACTION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_ACTION.NAV_LABEL',
          'hover': ' Actions & Recommendations',
          'breadcrumb': 'Actions & Recommendations',
          'icon': '',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Asset Harvester',
          'link': 'Asset-Harvester-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SITE_SETUP.NAV_LABEL',
          'hover': ' Asset Harvester ',
          'breadcrumb': 'Asset Harvester',
          'icon': '',
          'subNavigation': [],
          'role': ['28', '29']
        },
        {
          'name': 'Data Visualization',
          'link': 'data-Visualization-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_DATA_VISUALIZATION.NAV_LABEL',
          'hover': 'Data Visualization',
          'breadcrumb': 'Data Visualization',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['9']
        },
        {
          'name': 'Financial Impact',
          'link': 'financial-impact-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_FINANCIAL_IMPACT.NAV_LABEL',
          'hover': 'Financial Impact',
          'breadcrumb': 'Financial Impact',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['8', '15']
        },
        {
          'name': 'My Documents',
          'link': 'my-documents-Uservoice',
          'labelMobile': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS_MOBILE',
          'label': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
          'hover': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
          'breadcrumb': 'My Documents',
          'text': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
          'icon': 'ico-nav-my-documents',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Omni',
          'link': 'Omni-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_OMNI.NAV_LABEL',
          'hover': ' Omni ',
          'breadcrumb': 'Omni',
          'icon': '',
          'subNavigation': [],
          'role': ['5']
        },
        {
          'name': 'Service Summary Dashboard',
          'link': 'service-summary-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_SSD.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_SSD.NAV_LABEL',
          'hover': ' Service Summary Dashboard',
          'breadcrumb': 'Service Summary Dashboard',
          'icon': '',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'Water Flow Intelligence',
          'link': 'water-Flow-intelligence-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_FLOW_INTELLIGENCE.NAV_LABEL',
          'hover': 'Water Flow Intelligence',
          'breadcrumb': 'Water Flow Intelligence',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'role': ['7']
        },
        {
          'name': 'Water Quality',
          'link': 'Water-Quality-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_QUALITY.NAV_LABEL',
          'hover': ' Water Quality ',
          'breadcrumb': 'Water Quality',
          'icon': '',
          'subNavigation': [],
          'role': ['4', '11']
        },
        {
          'name': 'Water Safety Intelligence',
          'link': 'Water-Safety-Uservoice',
          'labelMobile': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL_MOBILE',
          'label': 'LEFT_NAV.SUBNAV_WATER_SAFETY.NAV_LABEL',
          'hover': 'Water Safety Intelligence',
          'breadcrumb': 'Water Safety Intelligence',
          'icon': '',
          'subNavigation': [],
          'role': ['2', '10']
        }
      ]
    },
    {
      'name': 'Navigation - Main - My Documents',
      'link': '/my-documents',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS_MOBILE',
      'label': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
      'hover': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
      'breadcrumb': 'My Documents',
      'text': 'LEFT_NAV.MAIN_NAV_MYDOCUMENTS',
      'icon': 'ico-nav-my-documents',
      'subNavigation': [],
      'role': ['1']
    },
    {
      'name': 'Navigation - Main - Envision',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_ENVISION',
      'label': 'LEFT_NAV.MAIN_NAV_ENVISION',
      'hover': '',
      'breadcrumb': 'LEFT_NAV.MAIN_NAV_ENVISION',
      'text': 'LEFT_NAV.MAIN_NAV_ENVISION',
      'icon': '',
      'subNavigation': [
        {
          'name': 'Envision-Edata',
          'link': 'Envision-Edata',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.EDATA',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.EDATA',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.EDATA',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.EDATA',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': true,
          'role': ['1']
        },
      
        {
          'name': 'Envision-ERB',
          'link': 'Envision-ERB',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.ERB',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['1']
        },
      
        {
          'name': 'Envision-Alarms',
          'link': 'Envision-Alarms',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.ALARMS',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.ALARMS',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.ALARMS',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.ALARMS',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': true,
          'role': ['1']
        },
      
        {
          'name': 'Envision-Controller',
          'link': 'Envision-Controller',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONTROLLER',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': true,
          'role': ['1']
        },
      
        {
          'name': 'Envision-Configurator',
          'link': 'Envision-Configurator',
          'labelMobile': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'label': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'hover': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'breadcrumb': 'LEFT_NAV.SUBNAV_ENVISION.CONFIGURATOR',
          'icon': 'icon-icon_admin',
          'subNavigation': [],
          'isVisible': false,
          'role': ['1']
        }],
      'role': ['1']
    },
    {
      'name': 'Common Pages',
      'link': '',
      'labelMobile': 'LEFT_NAV.MAIN_NAV_COMMONPAGES',
      'label': 'LEFT_NAV.MAIN_NAV_COMMONPAGES',
      'hover': '',
      'breadcrumb': 'Common Pages',
      'text': 'LEFT_NAV.MAIN_NAV_COMMONPAGES',
      'icon': 'ico-nav-common',
      'subNavigation': [
        {
          'name': 'Emergency Numbers',
          'link': 'common-pages/emergency-numbers',
          'labelMobile': 'LEFT_NAV.EMERGENCY',
          'label': 'LEFT_NAV.EMERGENCY',
          'hover': '',
          'breadcrumb': 'Emergency Numbers',
          'icon': '',
          'subNavigation': [],
          'role': ['1']
        },
        {
          'name': 'SH&E Reference',
          'link': 'common-pages/safety-health',
          'labelMobile': 'LEFT_NAV.SH&E',
          'label': 'LEFT_NAV.SH&E',
          'hover': 'SH&E Reference',
          'breadcrumb': 'SH&E Reference',
          'icon': '',
          'subNavigation': [],
          'role': ['1']
        }
      ]
    }
  ],
  'topNavigation': []
};
