import * as contracts from '../contracts/_contracts';
import { AccountSearch } from './AccountSearch';

export class User implements contracts.IUser {

    private static _user: User;

    authenticated: boolean = false;
    userPrincipalName?: string;
    profile: UserProfile;

    constructor(user?: contracts.IUser) {
        if (user) {
            this.authenticated = true; // BYPASS Authentication steps
            this.userPrincipalName = user.EmailAddress;
            if (user) {
                this.profile = new UserProfile(user);
            }

            User._user = this;
        }
    }

    public static currentUser() {
        return User._user;
    }

    public getInitials() {
        let firstName = "this.profile.given_name";
        let lastName = "this.profile.family_name";
        if (!firstName || !lastName) {
            const name = this.profile.userPrincipalName.split('.');
            firstName = name[0];
            lastName = name[1];
        }

        return (firstName.substr(0, 1) + lastName.substr(0, 1)).toUpperCase();
    }
}

export class UserProfile implements contracts.IUserProfile {
    mail?: string;
    surname?: string;
    givenName?: string;
    userPrincipalName?: string;

    constructor(userProfile?: contracts.IUserProfile) {
        if (userProfile) {
            //console.log('User Profile=>', userProfile);
            this.mail = userProfile.EmailAddress;
            this.surname = userProfile.LastName;
            this.givenName = userProfile.FirstName;
            this.userPrincipalName = userProfile.EmailAddress;
            // if we ever going to use anything populate further
        }
    }

    getInitials() {
        let firstName = this.givenName;
        let lastName = this.surname;
        if (!firstName || !lastName) {
            const name = this.userPrincipalName.split('.');
            firstName = name[0];
            lastName = name[1];
        }

        return (firstName.substr(0, 1) + lastName.substr(0, 1)).toUpperCase();
    }
}

export class UserPreference {
    UnitsOfMeasure: string;
    Currency: Currency;
    constructor(userPreference?: UserPreference) {
        Object.assign(this, userPreference);
    }
}

export class Currency {
    CurrencyCodeId: string;
    CurrencyName: string;
    Symbol: string;
    constructor(currency?: Currency) {
        Object.assign(this, currency);
    }
}


export class UserSettings {
    DefaultContextPointId: number;
    DefaultContextPointName: string;
    DefaultSiteId?: string;
    IsInternalUser: boolean;
    IsSingleSiteUser?: boolean;
    Permissions: Array<any>;
    Accounts: AccountSearch[];
    UserPreference: UserPreference;
    Culture: string;
    UserId: number;


    constructor(values: UserSettings) {
        Object.assign(this, values);
    }
}